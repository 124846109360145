import React from "react";
import NorthstarPageLayout from "../../../components/NorthstarPageLayout";
import ToolsComponent from "../../../components/Tools";
import headerBackground from "../../../assets/northstar/header_bg.png"
interface ToolsPageProps {
    addBanner?: boolean
}

const Tools: React.FC<ToolsPageProps> = ({ addBanner = true }) => {
    return (
        <>
            <NorthstarPageLayout title="Tools">
                <>
                    {
                        addBanner &&
                            <div
                                className="mb-6 w-full h-36 rounded"
                                style={{
                                    backgroundImage: `url(${headerBackground})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "bottom"
                                }}
                            >
                                <div className="p-3">
                                    <div className="text-blue text-xl font-title">Tools</div>
                                    <div className="text-gray-dark text-xs md:text-sm">Interactive data tools allow you to create insights at-scale using our data and models, then easily operationalize those insights​.</div>
                                </div>
                            </div>
                            
                    }
                    <ToolsComponent showTitle={!addBanner}/>
                </>
            </NorthstarPageLayout>
        </>
    )
}

export default Tools